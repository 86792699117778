import React, { useEffect, useState } from "react";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import ButtonDefault from "../../../../components/button/ButtonDefault";

import { useDispatch, useSelector } from "react-redux";

import { clientDataProjectAction } from "../../../../../redux/action/utilsAction";

const AddHeadline = (props) => {
  const initialFormData = {
    keyword: "",
    category: null,
    ideationFeature: {
      what: null,
      when: null,
      where: null,
      why: null,
      who: null,
      how: null,
    },
    gender: null,
    age: null,
    media: null,
    SES: null,
    stakeholder: null,
    objective: "",
    attribute: "",
    note: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formError, setFormError] = useState({});
  const [question] = useState([
    {
      what: "Apa nama layanan/fitur baru yang akan diluncurkan?",
      when: "Kapan layanan baru tersebut tersedia secara luas bisa diakses?",
      where:
        "Dimanakah masyarakat/user dapat mengakses layanan/produk baru tersebut?",
      why: "",
      who: "Siapa yang menjadi pengguna dari layanan/produk baru tersebut?",
      how:
        "Bagaimana perusahaan melihat potensi ke depannya dari produk/layanan baru tersebut?",
    },
    {
      what: "Apa bentuk kerjasama baru yang sedang dilakukan?",
      when: "Kapan kerjasama tersebut akan dijalankan?",
      where: "Dimana kerjasama tersebut disetujui/disahkan?",
      why: "Kenapa kerjasama tersebut dapat terbentuk?",
      who: "Siapa yang menjadi inisiator terjalinnya kerjasama tersebut?",
      how:
        "Bagaimana perusahaan melihat atau mengambil potensi dari kerjasama tersebut ke depannya?",
    },
    {
      what: "Apa isu yang akan diulas?",
      when: "Kapan isu tersebut bisa diselesaikan?",
      where: "Dimana letak pokok permasalahan dari isu tersebut?",
      why: "Kenapa isu tersebut bisa terjadi?",
      who: "Siapa yang bertanggungjawab atas isu tersebut?",
      how: "Bagaimana sebenarnya kronologi isu tersebut?",
    },
    {
      what: "Apa bentuk program CSR yang ingin diliput?",
      when: "Kapan program tersebut akan dilaksanakan atau diluncurkan?",
      where: "Dimana program tersebut akan berjalan dan terlaksana?",
      why: "Kenapa program tersebut penting dilakukan?",
      who: "Siapa saja yang menjadi target utama dari program tersebut?",
      how:
        "Bagaimana dampak program tersebut menunjang perusahaan dan masyarakat?",
    },
    {
      what: "Apa bentuk pencapaian perusahaan yang ingin dipublikasikan?",
      when: "Kapan kinerja tersebut dapat dicapai perusahaan?",
      where: "Di sektor/unit bisnis apa pencapaian tersebut terjadi?",
      why: "Kenapa kinerja tersebut perlu disampaikan ke publik?",
      who:
        "Siapa saja pihak yang terlibat dan berkontribusi dalam pencapaian tersebut?",
      how: "",
    },
  ]);

  const [selected, setSelected] = useState(0);

  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();

  const {
    dataAge,
    dataGender,
    dataStakeholder,
    dataCategoryRelease,
    dataAddProjectClient,
  } = useSelector((state) => state.utils);

  const ageDropdown = dataAge?.map((age) => ({ name: age, code: age }));
  const genderDropdown = dataGender?.map((gender) => ({
    name: gender,
    code: gender,
  }));
  const categoryDropdown = dataCategoryRelease?.map((category) => ({
    name: category,
    code: category,
  }));

  // data target media
  const dataMedia = [
    "Berita Umum",
    "Lifestyle",
    "Ekonomi & Bisnis",
    "Tech",
    "Startup",
    "Investasi",
    "Capital Market",
  ];

  const dataSES = ["SES A", "SES B", "SES C", "SES D"];

  const validateForm = () => {
    const errors = {};

    if (!formData.keyword) errors.keyword = "Kata Kunci harus diisi";
    if (!formData.category) errors.category = "Kategori harus diisi";
    if (!formData.ideationFeature.what)
      errors.ideationFeature = "Nama layanan/fitur harus diisi";
    if (!formData.objective)
      errors.objective = "Objektif Komunikasi harus diisi";
    if (!formData.gender) errors.gender = "Gender harus diisi";
    if (!formData.age) errors.age = "Usia harus diisi";
    if (!formData.media) errors.media = "Media harus diisi";
    if (!formData.SES) errors.SES = "SES harus diisi";
    if (!formData.stakeholder) errors.stakeholder = "Stakeholder harus diisi";

    return errors;
  };

  useEffect(() => {
    if (formData.category === "Fitur/layanan dan Produk Baru") {
      setSelected(0);
    } else if (formData.category === "Kerjasama Baru Perusahaan") {
      setSelected(1);
    } else if (formData.category === "Klarifikasi Isu atau Kasus") {
      setSelected(2);
    } else if (formData.category === "Update CSR program") {
      setSelected(3);
    } else if (formData.category === "Kinerja Perusahaan") {
      setSelected(4);
    }
  }, [formData.category]);

  useEffect(() => {
    if (
      formData?.keyword?.length > 0 &&
      formData?.ideationFeature.what?.length > 0 &&
      formData?.objective?.length > 0 &&
      formData?.stakeholder?.length > 0 &&
      formData?.SES?.length > 0 &&
      formData?.category !== null &&
      formData?.age !== null &&
      formData?.media?.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  useEffect(() => {
    if (dataAddProjectClient !== "") {
      setFormData({
        keyword: dataAddProjectClient?.keyword || formData.keyword,
        ideationFeature: {
          what:
            dataAddProjectClient?.ideationFeature?.what ||
            formData.ideationFeature.what,
          when:
            dataAddProjectClient?.ideationFeature?.when ||
            formData.ideationFeature.when,
          where:
            dataAddProjectClient?.ideationFeature?.where ||
            formData.ideationFeature.where,
          why:
            dataAddProjectClient?.ideationFeature?.why ||
            formData.ideationFeature.why,
          who:
            dataAddProjectClient?.ideationFeature?.who ||
            formData.ideationFeature.who,
          how:
            dataAddProjectClient?.ideationFeature?.how ||
            formData.ideationFeature.how,
        },
        objective: dataAddProjectClient?.objective || formData.objective,
        attribute: dataAddProjectClient?.attribution || formData.attribute,
        category: dataAddProjectClient?.releaseCategory || formData.category,
        gender: dataAddProjectClient?.target?.sex || formData.gender,
        age: dataAddProjectClient?.target?.age || formData.age,
        media: dataAddProjectClient?.target?.media || formData.media,
        note: dataAddProjectClient?.releaseNote || formData.note,
        stakeholder:
          dataAddProjectClient?.target?.stakeholders || formData.stakeholder,
        SES: dataAddProjectClient?.target?.ses || formData.SES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddProjectClient]);

  const onCheck = (e, name) => {
    const { value, checked } = e.target;
    let selectedData = formData[name] ? [...formData[name]] : [];

    if (checked) {
      selectedData.push(value);
    } else {
      selectedData = selectedData.filter((item) => item !== value);
    }

    const newSES = dataSES.filter((item) => selectedData.includes(item));

    if (name === "SES") {
      setFormData({
        ...formData,
        [name]: newSES,
      });
    } else {
      setFormData({
        ...formData,
        [name]: selectedData,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("ideationFeature.")) {
      const ideationField = name.split("ideationFeature.")[1];

      setFormData((prev) => ({
        ...prev,
        ideationFeature: {
          ...prev.ideationFeature,
          [ideationField]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setFormError({
      ...formError,
      [name]: null,
    });
  };

  const submitDetail = (e) => {
    e.preventDefault();
    const errors = validateForm();

    const dataHeadline = {
      keyword: formData.keyword,
      ideationFeature: {
        what: formData.ideationFeature.what,
        when: formData.ideationFeature.when,
        where: formData.ideationFeature.where,
        why: formData.ideationFeature.why,
        who: formData.ideationFeature.who,
        how: formData.ideationFeature.how,
      },
      objective: formData.objective,
      releaseCategory: formData.category,
      releaseNote: formData.note,
      attribution: formData.attribute,
      target: {
        sex: formData.gender,
        age: formData.age,
        media: formData.media,
        stakeholders: formData.stakeholder,
        ses: formData.SES,
      },
      title: dataAddProjectClient?.title ? dataAddProjectClient?.title : null,
      category: dataAddProjectClient?.category
        ? dataAddProjectClient?.category
        : null,
      type: dataAddProjectClient?.type ? dataAddProjectClient?.type : null,
      quota: dataAddProjectClient?.quota ? dataAddProjectClient?.quota : null,
      desc: dataAddProjectClient?.desc ? dataAddProjectClient?.desc : null,
      deadline: dataAddProjectClient?.deadline
        ? dataAddProjectClient?.deadline
        : null,
    };

    if (Object.keys(errors).length === 0) {
      dispatch(clientDataProjectAction(dataHeadline));
      props.setHeadline();
    } else {
      setFormError(errors);
    }
  };

  return (
    <div className="card card-campaign">
      <div className="card-body pb-4 p-d-flex">
        <div className="form-add-project brief">
          <div className="p-field">
            <label className="p-d-block">
              Keyword Pencarian <span className="text-danger">*</span>
            </label>
            <InputText
              name="keyword"
              id="keyword"
              className="p-d-block"
              placeholder="Text here"
              onChange={handleChange}
              value={formData.keyword}
            />
            {formError.keyword && (
              <small id="keyword" className="p-error p-d-block p-text-right">
                {formError.keyword}
              </small>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block" id="release-category">
              Kategori Rilis <span className="text-danger">*</span>
            </label>
            <Dropdown
              value={formData.category}
              options={categoryDropdown}
              onChange={handleChange}
              optionLabel="name"
              optionValue="code"
              name="category"
              placeholder="Pilih Kategori Rilis"
            />
            {formError.category && (
              <small id="category" className="p-error p-d-block p-text-right">
                {formError.category}
              </small>
            )}
          </div>

          <div className="p-field">
            <label className="p-d-block" htmlFor="ideationFeature.what">
              {question?.[selected].what}
              <span className="text-danger">*</span>
            </label>
            <InputText
              name="ideationFeature.what"
              id="what"
              className="p-d-block"
              placeholder="Text here"
              onChange={handleChange}
              value={formData.ideationFeature.what}
            />
            {formError.ideationFeature && (
              <small id="what" className="p-error p-d-block p-text-right">
                {formError.ideationFeature}
              </small>
            )}
          </div>

          <div>
            <div className="p-field">
              <label className="p-d-block">{question?.[selected].who}</label>
              <InputText
                name="ideationFeature.who"
                id="who"
                className="p-d-block"
                placeholder="Text here"
                onChange={handleChange}
                value={formData.ideationFeature.who}
              />
            </div>

            <div className="p-field">
              <label className="p-d-block">{question?.[selected].where}</label>
              <InputText
                name="ideationFeature.where"
                id="where"
                className="p-d-block"
                placeholder="Text here"
                onChange={handleChange}
                value={formData.ideationFeature.where}
              />
            </div>

            <div className="p-field">
              <label className="p-d-block">{question?.[selected].when}</label>
              <InputText
                name="ideationFeature.when"
                id="when"
                className="p-d-block"
                placeholder="Text here"
                onChange={handleChange}
                value={formData.ideationFeature.when}
              />
            </div>

            {question?.[selected].why && (
              <div className="p-field">
                <label className="p-d-block">{question?.[selected].why}</label>
                <InputText
                  name="ideationFeature.why"
                  id="why"
                  className="p-d-block"
                  placeholder="Text here"
                  onChange={handleChange}
                  value={formData.ideationFeature.why}
                />
              </div>
            )}

            <div className="p-field">
              <label htmlFor="target" className="p-d-block">
                Target Pembaca
              </label>
              <div className="innef-field p-d-flex">
                <span>
                  Jenis Kelamin <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    name="gender"
                    id="gender"
                    value={formData.gender}
                    options={genderDropdown}
                    onChange={handleChange}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {formError.gender && (
                    <small
                      id="gender"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.gender}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Usia <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    name="age"
                    id="age"
                    value={formData.age}
                    options={ageDropdown}
                    onChange={handleChange}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {formError.age && (
                    <small id="age" className="p-error p-d-block p-text-right">
                      {formError.age}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Target Media <span className="text-danger">*</span>
                </span>
                <div>
                  {dataMedia?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        name="media"
                        id="media"
                        inputId={item}
                        onChange={(e) => onCheck(e, "media")}
                        checked={
                          formData.media ? formData.media.includes(item) : false
                        }
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {formError.media && (
                    <small
                      id="media"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.media}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  SES <span className="text-danger">*</span>
                </span>
                <div>
                  {dataSES?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        name="SES"
                        id="SES"
                        inputId={item}
                        onChange={(e) => onCheck(e, "SES")}
                        checked={
                          formData.SES ? formData.SES.includes(item) : false
                        }
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {formError.SES && (
                    <small id="SES" className="p-error p-d-block p-text-right">
                      {formError.SES}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field">
                <span>
                  Stakeholder <span className="text-danger">*</span>
                </span>
                <div>
                  {dataStakeholder?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        name="stakeholder"
                        id="stakeholder"
                        inputId={item}
                        onChange={(e) => onCheck(e, "stakeholder")}
                        checked={
                          formData.stakeholder
                            ? formData.stakeholder.includes(item)
                            : false
                        }
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {formError.stakeholder && (
                    <small
                      id="stakeholder"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.stakeholder}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="p-field">
            <label className="p-d-block">
              Tujuan Pembuatan Rilis <span className="text-danger">*</span>
            </label>
            <InputTextarea
              name="objective"
              id="objective"
              rows={5}
              cols={30}
              onChange={handleChange}
              value={formData.objective}
              placeholder="Text here"
            />
            {formError.objective && (
              <small id="objective" className="p-error p-d-block p-text-right">
                {formError.objective}
              </small>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block" id="delivered-attribution">
              Pesan utama yang ingin disampaikan
            </label>
            <InputTextarea
              name="attribute"
              id="attribute"
              rows={5}
              cols={30}
              onChange={handleChange}
              value={formData.attribute}
              placeholder="Text here"
            />
          </div>

          {question?.[selected].how && (
            <div className="p-field">
              <label className="p-d-block">{question?.[selected].how}</label>
              <InputText
                name="ideationFeature.how"
                id="how"
                className="p-d-block"
                placeholder="Text here"
                onChange={handleChange}
                value={formData.ideationFeature.how}
              />
            </div>
          )}

          <div className="p-field">
            <label className="p-d-block">
              Poin penting lainnya yang ingin disampaikan
            </label>
            <InputTextarea
              name="note"
              id="note"
              rows={5}
              cols={30}
              onChange={handleChange}
              value={formData.note}
              placeholder="Text here"
            />
          </div>

          <ButtonDefault
            onClick={submitDetail}
            disabled={isDisabled}
            label="Generate"
            type="rounded"
            className="p-button p-button-primary"
          />
        </div>
        <div className="ml-20 px-6 py-6 text-dark border rounded border-3 border-top-info h-100 campaign-info">
          <div className="h6 font-weight-bold mb-5">
            Tata cara pengisian informasi {formData.category}
          </div>
          <div className="mb-3">
            Deskripsikan dengan lengkap dan terperinci informasi lebih lanjut
            terkait {formData.category}, seperti:
          </div>
          <ul className="mb-3">
            <li>{question?.[selected].what}</li>
            <li>{question?.[selected].when}</li>
            <li>{question?.[selected].where}</li>
            {question?.[selected].why && <li>{question?.[selected].why}</li>}
            <li>{question?.[selected].who}</li>
            {question?.[selected].how && <li>{question?.[selected].how}</li>}
          </ul>
          <div>
            Tambahkan spesifikasi dan poin penting lainnya yang ingin
            disampaikan, agar dapat membantu penulis bekerja dengan baik.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHeadline;
