/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ButtonDefault from '../../../../components/button/ButtonDefault';
import { Skeleton } from '@material-ui/lab';
import { clientDataProjectAction } from '../../../../../redux/action/utilsAction';

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  /* apiKey: "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", */
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);


const AddStory = (props) => {
  const dispatch = useDispatch()
  const {dataAddProjectClient} = useSelector((state) => state.utils);
  const [dataChat, setDataChat] = useState([])
  const [allDataChat, setAllDataChat] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [isDisabled, setDisabled] = useState(false)

  useEffect(() => {
    if(count === 3) {
      setDisabled(true)
    }
  }, [count])
  
  const handleStart = () => {
    async function start() {
      setLoading(true)
      try {
        setCount(count + 1)
        const chatCompletion = await openai.createChatCompletion({
          model: "gpt-4o",
          messages: [
            {
              role: "user",
              content: `Tugasnya adalah membuat ide story di media sebagai ahli komunikasi. Buatlah 3 array dengan nomor index bertuliskan story angle dalam bahasa indonesia untuk press release ${dataAddProjectClient.releaseCategory}". 
              Pembuatan ide tersebut harus ringkas, informatif, menarik dan arus dipastikan mengandung esensi dari elemen berikut:
              1. Headline: Create an attention-grabbing headline that summarizes the main news or announcement.
              2. Talking points: 3 strong points that should include and support the headline and the article based on objective and target audience.
              
              Pembuatan ide story angle harus mengandung kata kunci ${dataAddProjectClient.keyword}. Objektif yang ingin dicapai dari ide story tersebut adalah ${dataAddProjectClient.objective}. Atribusi lain yang ingin disampaikan adalah ${dataAddProjectClient.attribution}.
              Penjelasan lain terkait ${dataAddProjectClient.releaseCategory} adalah:
              ${dataAddProjectClient.what ? `${dataAddProjectClient.what}.` : ""}
              ${dataAddProjectClient.when ? `Dapat digunakan ${dataAddProjectClient.when}.` : ""}
              ${dataAddProjectClient.where ? `Dapat diakses dari ${dataAddProjectClient.where}.` : ""}
              ${dataAddProjectClient.who ? `Target audiens ${dataAddProjectClient.who}.` : ""}
              
              Target pembaca dari ide tersebut: ${String(dataAddProjectClient?.target?.ses)}, Investor ${String(dataAddProjectClient?.target?.stakeholders)}, usia ${dataAddProjectClient?.target?.age}, ${dataAddProjectClient?.target?.sex}.

              Your response should be in array format with two parameters 'headline', 'talkingPoints', and 'isSelected: false' inside the array`
            }
          ],
        });
        /* const jsonResponse = JSON.parse(chatCompletion.data.choices[0].message.content) */
        const cleanResponse = chatCompletion.data.choices[0].message.content
          .replace(/```json\n/g, "")
          .replace(/```/g, "")
          .trim();
        const jsonResponse = JSON.parse(cleanResponse);

        const newDataChat = allDataChat.concat(jsonResponse);
        setAllDataChat(newDataChat)
        setDataChat(jsonResponse)
        setLoading(false)
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    start()
  }

  useEffect(() => {
    handleStart()
  }, [])
  
  const handleNext = (e) => {
    var index = allDataChat.findIndex(item => item.headline === e.headline && item.talkingPoints === e.talkingPoints);
    var isSelect = true
    allDataChat[index].isSelected = isSelect;

    const dataRelease = {
      keyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: allDataChat,
      title: e.headline,
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      // releaseCategoryQuestion: dataAddProjectClient.releaseCategoryQuestion,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
    };
    dispatch(clientDataProjectAction(dataRelease));
    props.submitDetail()
  }

  return (
    <>
        <div className="text-dark px-8 mb-8">
            <div className="h2 mb-2">Rekomendasi</div>
            <div className="">Pilih rekomendasi story angle berdasarkan brief yang kamu berikan.</div>
            {!isDisabled ? (
              <div className='d-flex justify-content-end'>
                <ButtonDefault
                  onClick={handleStart}
                  label="RE-GENERATE"
                  type="rounded"
                  className="w-30 p-button p-button-rounded p-mt-4 p-button-outlined"
                />
              </div>
            ) : null}
        </div>
        <div className='mx-8 text-dark mb-8'>
          {isLoading ? (
            <>
              <div className='mb-5'>Proses Pembuatan Story Angle. Mohon Tunggu..</div>
              <div className='px-5 py-8 border border-2 rounded'>
                <div className='mb-3'><Skeleton animation="wave" variant='text' height={40} width={300}/></div>
                <div className='mb-5'>
                  <Skeleton animation="wave" variant='text'/>
                  <Skeleton animation="wave" variant='text' width={300}/>
                  <Skeleton animation="wave" variant='text' width={340}/>
                  <Skeleton animation="wave" variant='text'/>
                  <Skeleton animation="wave" variant='text'width={300}/>
                </div>

                <ButtonDefault
                  onClick={props.submitAngle}
                  label="PILIH ANGLE STORY"
                  disabled={true}
                  type="rounded"
                  className="p-button p-button-primary w-100"
                />
              </div>
            </>
          ) : (
            <div>
              {dataChat.map((item, i) => (
                <div key={i} className='px-5 py-8 mb-5 border border-2 rounded'>
                  <div className='mb-3 h4'>{item.headline}</div>
                  <div className='mb-5'>
                    {item.talkingPoints.map((point, index) => (
                      <li key={index} className='mb-1'>{point}</li>
                    ))}
                  </div>
                      
                  <ButtonDefault
                    onClick={() => handleNext(item)}
                    label="PILIH ANGLE STORY"
                    type="rounded"
                    className="p-button p-button-primary w-100"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
    </>
  )
}

export default AddStory